<template>
  <section>
    <HeroSection data-aos="fade-up" />
    
    <div class="container">
      <WeddingPackage data-aos="fade-right" />
    </div>
    
    <div class="container py-5">
      <div class="card shadow-lg border-0" style="background: #74dfd9;" data-aos="fade-up">
        <div class="card-body p-4">
          <h2 class="heading text-center text-primary fw-bold mb-4 text-white">Celebrate Your Love</h2>
          <p class="fs-5 text-muted">
            Celebrate your love with a dreamy ceremony on your own private island, complete with a floral arch, professional photography, hair and makeup, wedding dance lessons, beautiful accommodation, official marriage certificate, and unforgettable lagoon tour – all for <span class="text-success fw-bold">NZD $18,999</span>.
          </p>
          <p class="fs-5 text-muted">
            Tailor your experience by removing or adding services to suit your desires and your budget, subject to availability.
          </p>
          <p class="fs-5 text-muted">
            Let us craft a day as unique and beautiful as your love story.
          </p>
        </div>
      </div>
    </div>

    <ServicesDescription data-aos="fade-left" />
    <GallerySection data-aos="zoom-in" />
    
    <div class="container px-3">
      <TestimonialsBit data-aos="fade-up" />
    </div>
    
    <FaqBit data-aos="fade-down" />
    <ContactForm data-aos="fade-up" />
    <FooterBit data-aos="fade-up" />
  </section>
</template>

<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import HeroSection from './components/HeroSection.vue';
import WeddingPackage from './components/WeddingPackage.vue';
import TestimonialsBit from './components/TestimonialsBit.vue';
import ContactForm from './components/ContactForm.vue';
import FooterBit from './components/FooterBit.vue';
import FaqBit from './components/FaqBit.vue';
import GallerySection from './components/GallerySection.vue';
import ServicesDescription from './components/ServicesDescription.vue';

export default {
  name: 'App',
  components: {
    HeroSection,
    WeddingPackage,
    ServicesDescription,
    TestimonialsBit,
    FaqBit,
    ContactForm,
    FooterBit,
    GallerySection,
  },
  mounted() {
    document.title = "A Dream Wedding on a Private Island";
    AOS.init({
      duration: 1000, // Animation duration in ms
      easing: 'ease-in-out', // Smooth animation easing
      once: true, // Animation runs only once
    });
  },
};
</script>

<style>
/* General Styling */
body {
  padding: 0;
  margin: 0;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  color: #333;
  background-color: #fdf1d7;
}

#app {
  text-align: center;
  color: #2c3e50;
  background: #fdf1d7;
  margin: 0;
  overflow: hidden;
}

.heading {
  font-family: "MonteCarlo", cursive;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

/* AOS-specific transitions */
[data-aos] {
  transition-property: transform, opacity;
  transition-duration: 0.6s; /* Matches AOS duration */
}

/* Hero Section */
.hero-section {
  height: 100vh;
  background-size: cover;
  background-position: center;
}

/* Wedding Package Styling */
.card {
  border-radius: 10px;
}

.card-body {
  padding: 20px 30px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}

.text-muted {
  font-size: 1rem;
  line-height: 1.6;
}

/* Footer and Contact */
.footer {
  background-color: #2c3e50;
  color: white;
  padding: 20px 0;
}
</style>
