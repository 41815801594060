<template>
    <section class="package">
      <h2>The Aitutaki Honeymoon Private Island Wedding Package includes:</h2>
      
      <div class="package-grid">
        <div class="package-item card p-2" v-for="(item, index) in packages" :key="index">
          <div class="card-header bg-transparent p-0 m-0 border-0">
            <h3>{{ item.title }}</h3>
          </div>
          <div class="card-body p-1">
            <p>{{ item.description }}</p>
          </div>
          <div class="card-footer m-0 p-0 border-0 bg-transparent my-auto">
            <button class="btn btn-outline-primary me-2 mb-3" type="button" data-bs-toggle="modal" :data-bs-target="'#' + item.modal">View More</button>
          </div>
        </div>
      </div>

    </section>


    <div class="modal fade" id="returnFlights" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Return Flights</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h3 class="text-info">Travel to Paradise</h3>
            <p>
              Round-trip flights from Auckland to Aitutaki, via Rarotonga, along with convenient airport transfers to and from your resort, ensure your journey is as effortless as your stay.
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="accomodation" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">4 Nights Accommodation</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <h3 class="text-info">Travel to Paradise</h3>
            <div class="row">

              <div class="col-md-6 row">
                <!-- <div class="owl-carousel2 owl-theme">
                  <div
                    class="item hero-slide"
                    v-for="(slide, index) in accomodation"
                    :key="index"
                  >
                    <img :src="slide.image" class="img-thumbnail rounded-0 d-block mx-auto" alt="">
                  </div>
                </div> -->
                <img v-for="(slide, index) in accomodation" :key="index" :src="slide.image" class="img-thumbnail rounded mx-auto col-md-6 border-0" alt="">
              </div>
              <div class="col-md-6">
                <p>
                  Experience unparalleled beauty with a four-night stay at the Aitutaki Village Resort, where every moment feels like a dream come true. Nestled in the heart of paradise, this resort offers an idyllic escape with breathtaking views and exceptional comfort, ensuring an unforgettable retreat.
                </p>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="photography" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Photography</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              Capture every magical moment with exceptional wedding photography that tells the story of your love. Our talented photographer will create timeless images you'll cherish forever.
            </p>
            <div class="container text-left">
              <h5>Bundle includes:</h5>
              <ul class="list-group">
                <li class="list-group-item">2 hours of photographic coverage</li>
                <li class="list-group-item">Minimum of 80 professionally edited photos</li>
                <li class="list-group-item">Digitally sent via an online gallery</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="hairMakeup" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Hair & Makeup</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>
              Indulge in a luxurious hair and makeup session to make you feel stunning on your special day. Our expert stylists will create a flawless look that complements your beauty and the occasion.
            </p>
            
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="dance" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Dance Lessons</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <img :src="require('@/assets/bg/dance.jpg')" class="img-thumbnail border-0 rounded mx-auto" alt="Hair and Makeup"/>
              </div>
              <div class="col-md-6">
                <p>
                  Learn from the best with wedding dance lessons from a seven-time New Zealand national ballroom champion. With expert instruction, you'll glide effortlessly across the floor, making your first dance truly unforgettable.
                  <a href="https://dancetilldawn.art" target="_blank">dancetilldawn.art</a>
                </p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="vows" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Marriage certificate or renewal of vows</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container">
              <ul class="list-group">
                <li class="list-group-item d-flex align-items-center">
                  Your own personal wedding Coordinator
                </li>
                <li class="list-group-item d-flex align-items-center">
                  Return transfers to Registry Office Aitutaki
                </li>
                <li class="list-group-item d-flex align-items-center">
                  Marriage license arrangement including apostille seal
                </li>
                <li class="list-group-item d-flex align-items-center">
                  Waiver if required
                </li>
                <li class="list-group-item d-flex align-items-center">
                  Celebrant fee
                </li>
                <li class="list-group-item d-flex align-items-center">
                  Witnesses (if required)
                </li>
              </ul>
            </div>

            
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="ceremony" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Ceremony</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="container">
              Experience a breathtaking wedding ceremony on your own Honeymoon private island, where the serene beauty of nature surrounds you. With the ocean as your backdrop and the sand beneath your feet, your special day will feel like a dream come true. 
            </div>
            <div class="container">
              <h4>
                Ceremony includes:
              </h4>
              <ul class="list-group">
                <li class="list-group-item">Tropical Floral bouquet for bride</li>
                <li class="list-group-item">Tropical neck ei for groom</li>
                <li class="list-group-item">Tropical Archway</li>
                <li class="list-group-item">Decorated signing table</li>
                <li class="list-group-item">1 bottle of Bubbly to toast</li>
                <li class="list-group-item">Champagne glasses, ice and champagne bucket</li>
                <li class="list-group-item">Return transfers from accommodation to boat</li>
                <li class="list-group-item">Boat & Captain</li>
                <li class="list-group-item">Ceremony venue, on your Own Tropical Island (Honeymoon Island)</li>
                <li class="list-group-item">Additional Persons and Added extras POA</li>
              </ul>
            </div>


            
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="tour" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Lagoon Tour</h4>
            <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12"><img :src="tour[0].image" alt="" class="img-thumbnail border-0 rounded mx-auto"></div>
                  <div class="col-6"><img :src="tour[1].image" alt="" class="img-thumbnail border-0 rounded mx-auto"></div>
                  <div class="col-6"><img :src="tour[2].image" alt="" class="img-thumbnail border-0 rounded mx-auto"></div>
                  <div class="col-8"><img :src="tour[3].image" alt="" class="img-thumbnail border-0 rounded mx-auto"></div>
                  <div class="col-4"><img :src="tour[4].image" alt="" class="img-thumbnail border-0 rounded mx-auto"></div>
                </div>
              </div>
              <div class="col-md-6">
                <p>One day before or after your wedding, embark on a breathtaking private Lagoon Tour, where you’ll explore the crystal-clear waters and vibrant marine life, including snorkeling with giant clams, Trevally fish and purple corals. Visit the stunning famous One Foot Island to get your passport stamped and of course Honeymoon Island, creating unforgettable memories in a paradise like no other.</p>
              </div>
            </div>

            
          </div>
        </div>
      </div>
    </div>

    


  </template>
  
  <script>
  export default {
    data() {
      return {
        packages: [
          {
            title: "Return Flights",
            description: "Enjoy roundtrip flights from Auckland to Aitutaki.",
            modal: 'returnFlights',
          },
          {
            title: "4 Nights Accommodation",
            description: "",
            modal: 'accomodation'
          },
          {
            title: "Wedding Photography",
            description: "Capture your special day with a professional photographer.",
            modal: "photography"
          },
          {
            title: "Hair & Makeup",
            description: "Look your best with our professional styling team.",
            modal: "hairMakeup"
          },
          {
            title: "Dance Lessons",
            description: "Enjoy 4 dance lessons to perfect your first wedding dance.",
            modal: "dance"
          },
          {
            title: "Marriage certificate or renewal of vows",
            description: "We handle all legal paperwork, including apostille seal.",
            modal: "vows"
          },
          {
            title: "Ceremony",
            description: "We handle all legal paperwork, including apostille seal.",
            modal: "ceremony"
          },
          {
            title: "Lagoon Tour",
            description: "We handle all legal paperwork, including apostille seal.",
            modal: "tour"
          },
        ],
        accomodation : [
          
          {
            image: require('@/assets/accomodation/two.jpg')
          },
          {
            image: require('@/assets/accomodation/three.jpg')
          },
          {
            image: require('@/assets/accomodation/one.jpg')
          },
        ],
        tour: [
          {image: require('@/assets/tour/one.jpg')},
          {image: require('@/assets/tour/two.jpg')},
          {image: require('@/assets/tour/three.jpg')},
          {image: require('@/assets/tour/four.jpg')},
          {image: require('@/assets/tour/five.jpg')},
        ]
      };
    },
    methods: {
      initOwlCarousel() {
        const $carousel = $(".owl-carousel2");
        $carousel.owlCarousel("destroy"); // Destroy existing carousel
        $carousel.owlCarousel({
          items: 1,
          loop: true,
          nav: true,
          dots: true,
          autoplay: true,
          autoplayTimeout: 3000,
        });
      },
    },
    mounted() {
      $("#accomodation").on("shown.bs.modal", () => {
        this.initOwlCarousel();
      });
      $("#accomodation").on("hidden.bs.modal", () => {
        $(".owl-carousel2").owlCarousel("destroy");
      });
    },
  };
  </script>
  
  <style scoped>
 

  .package {
    padding: 40px 20px;
    /* background-color: #03b5bb; */
  }
  .modal-title{
    font-family: "MonteCarlo", cursive;
    font-size: 2rem;
  }
  .package h2 {
    text-align: center;
    color: #2a4797;
    font-size: 2.5rem;
    font-weight: bold;
    font-family: "MonteCarlo", cursive;
  }
  .package-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1.5fr));
    gap: 10px;
  }
  .package-item {
    /* background: #74dfd9; */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
  }
  .package-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }


  h3 {
    font-family: "MonteCarlo", cursive;
    font-size: 1.7rem;
    color: var(--bs-primary);
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
  }
  .package-item p {
    font-size: 0.95rem;
    color: #555;
  }
  </style>
  