<template>
  <section class="gallery py-5">
    <div class="container">
      <h2 class="text-center mb-4">Our Beautiful Moments</h2>
      <div class="row g-4">
        <div class="col-12 col-sm-6 col-md-4" v-for="(image, index) in images" :key="index">
          <div class="gallery-item" @click="openLightbox(index)">
            <img :src="image" alt="Gallery Image" class="img-fluid rounded" />
          </div>
        </div>
      </div>
    </div>

    <!-- Lightbox Modal -->
    <div v-if="lightboxVisible" class="lightbox" @click="closeLightbox">
      <img :src="images[currentImage]" alt="Gallery Image" class="lightbox-image" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      images: [
        require('@/assets/gallery/gallery2.jpg'),
        require('@/assets/gallery/gallery3.jpg'),
        require('@/assets/gallery/gallery4.jpg'),
        require('@/assets/gallery/gallery5.jpg'),
        require('@/assets/gallery/gallery1.jpg'),
        require('@/assets/gallery/gallery6.jpg'),
        require('@/assets/gallery/gallery7.jpg'),
        require('@/assets/gallery/gallery8.jpg'),
      ],
      lightboxVisible: false,
      currentImage: 0,
    };
  },
  methods: {
    openLightbox(index) {
      this.currentImage = index;
      this.lightboxVisible = true;
    },
    closeLightbox() {
      this.lightboxVisible = false;
    },
  },
};
</script>

<style scoped>
.gallery {
  /* background-color: #f8f9fa; */
}

.gallery h2 {
  font-size: 3rem;
  font-weight: bold;
  color: #2a6f97;
  font-family: "MonteCarlo", cursive;
}

.gallery-item {
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

.gallery-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

.lightbox:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.lightbox img {
  z-index: 1001;
}

.lightbox:active {
  cursor: zoom-out;
}
</style>
